import CryptoJS from 'crypto-js'
import NotFound from '../pages/Notfound'
import axios from 'axios';
import Config from '../services/Config';
export const sleep = (delay = 0) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay)
  })
}

export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const regularExpressionsForValidation = () => {
  return {
    url: /^((http|https):\/\/)?(www\.)?(?!.*(http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    phone: /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    redirect_url: /^((http|https):\/\/)?(www\.)?(?!.*(http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$|^((http|https):\/\/)?([0-9]{1,3}\.){3}[0-9]{1,3}(:[0-9]{1,5})?\/?([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  };
};


export const showErrors = (field, valueLen, min) => {
  if (valueLen === 0) {
    return `${field} field is required`
  } else if (valueLen > 0 && valueLen < min) {
    return `${field} must be at least ${min} characters`
  } else {
    return ''
  }
}

export const differenceBetweenDates = (startDate, endDate) => {
  const date1 = new Date(startDate)
  const date2 = new Date(endDate)
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return diffDays
}

export const decryptData = (data) => {
  let decryptedData = null
  if (data !== null) {
    if (data.includes('{')) {
      localStorage.removeItem('userData')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userInfo')
      window.location.reload()
    } else {
      const bytes = CryptoJS.AES.decrypt(data, 'ORMdataEncode#$%')
      decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    }
  }

  return decryptedData
}

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, 'ORMdataEncode#$%').toString()
}

export const getUserData = () => {
  const signupData = window.localStorage.getItem('userData')
  const userD = decryptData(signupData)
  const allData = JSON.parse(userD)

  return allData
}


export const getdataFromLocalStorage = (key) => {
  const signupData = window.localStorage.getItem(key)
  const userD = decryptData(signupData)
  const allData = JSON.parse(userD)

  return allData
}

export const monthMapping = () => {
  return {
    January: '1',
    February: '2',
    March: '3',
    April: '4',
    May: '5',
    June: '6',
    July: '7',
    August: '8',
    September: '9',
    October: '10',
    November: '11',
    December: '12',
  };
}

export const errorMessage = (props) => {
  try {
    if (props?.response?.status === 404 || props?.response?.status === 500) {
      window.location.href = '/404-not-found'
    }
    return true;
  } catch (error) {
    console.log(error.message);
    return true
  }
}

export const formattedName = (str) => {
  return decodeURI(str)
    // .replace(/-/g, ' ') 
    // .replace(/\b\w/g, (char) => char.toUpperCase()); 
};
export const encodedName = (str) => {
  return encodeURI(str)?.replace('/','%2F')
};

export const checkImage = (path) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: "ok" });
    img.onerror = () => resolve({ path, status: "error" });
    img.src = path;
  });


  export const errorHandling = (props) => {
    try {
      if (props?.response?.status === 422 || props?.response?.status === 401) {
        const signOut = () => {
          const token = localStorage.getItem("Token");
      
          // setUser(null);
          window.localStorage.removeItem('userData');
          window.localStorage.removeItem('Token');
          window.localStorage.removeItem('cartItems');
          window.localStorage.removeItem('cartCount');
          window.localStorage.removeItem('checkoutItemIds');
          window.localStorage.removeItem('categoriesName');
      
          (async () => {
            try {
              const response = await axios.post(
                `${Config.baseURL}${Config.logOut}`,
                null,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              window.location.href= "/login"
            } catch (error) {
              console.log("error:", error);
            }
          })();
        };
        signOut(); // Call signOut function to execute sign out process
      }
      return true;
    } catch (error) {
      console.log(error.message);
      return true
    }
  }


  export const UpdatedDecimalDigits = (number) => {
    const numberString = number.toString();

    if (numberString.includes('.')) {
        const [integerPart, decimalPart] = numberString.split('.');
        const decimalCount = decimalPart.length;

        // Determine if we should format with 2 or 4 decimal places
        const formattedNumber = decimalCount <= 2
            ? Number(number).toFixed(2)
            : decimalCount >= 4 && !(decimalPart.endsWith('00') || decimalPart.endsWith('000'))
            ? Number(number).toFixed(2)
            : Number(number).toFixed(2);

        // Use Intl.NumberFormat to add comma separators
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: decimalCount <= 2 ? 2 : 2, //4
            maximumFractionDigits: decimalCount <= 2 ? 2 : 2 //4
        }).format(formattedNumber);
    } else {
        // Format the integer number with comma separators and two decimal places
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(Number(number).toFixed(2));
    }
}

