const apiURL = process.env.REACT_APP_LOCAL_DEVELOPMENT_URL
const backendURL = process.env.REACT_APP_PUBLIC_BACKEND_URL
const url = process.env.REACT_APP_PUBLIC_FRONT_URL
const s3BucketImagePath = process.env.REACT_APP_S3_BUCKET_IMAGE_PATH

const pathObj = {
  baseURL: apiURL,
  frontUrl: url,
  backendURL:backendURL,
  s3ImagePath:s3BucketImagePath,

  // worldpay data
  worldpayInstId: process.env.REACT_APP_WORLDPAY_INST_ID, 
  worldpayPaymentMode: process.env.REACT_APP_WORLDPAY_PAYMENT_MODE, // 100 for demo and 0 for live
  worldpayPaymentUrl: process.env.REACT_APP_WORLD_PAYMENT_URL,
  worldpayPassword: process.env.REACT_APP_WORLDPAY_PASSWORD,

  /* API URLs */
  logIn: '/login',
  logOut: '/logout',
  forgotPassword: '/forgot_password',
  resetPassword: '/reset-password',
  getHeaderInfo: '/headerInfo',
  getFooterInfo: '/footerDetails',
  getHeaderBannerInfo: '/bannerDetails',
  getMegaMenu: '/megaMenus',
  getTopCategories: '/topCategories',
  pageDetail: '/pageDetail',
  accountApplication: '/saveApplicationForm',
  getProductListing: '/productListing',
  getProductDetails: '/productDetail',
  getCategoryDetails: '/getCategoryList',
  getNewsList: '/getNewsList',
  newsCategories : '/newsCategories ',
  newsDetail : '/newsDetail ',
  contactFormSubmit: '/saveContactForm',
  getAlternativeRelatedProducts: '/getRelatedProducts',
  addCart: '/AddCart',
  getCartDetails: '/getCartItems',
  deleteIndividualItem: '/deleteCartItem',
  getCheckoutDetails: '/orderCartItems',
  getRecentPurchsedItems: '/getRecentPurchsedItems',
  saveOrder: '/saveOrder',
  brainTreeCheckout: '/braintreeCheckout', 
  paymentSettings: '/pyamentSettings',  
  worldPayCheckout: '/create-payment-session',
  brainTreeToken: '/braintreeToken',
  saveRating: '/saveRating',
  searchResult: '/searchProducts',
  saveFavoriteItem: '/saveFavoriteItem',
  getFavouriteItems: '/getFavouriteItems',
  deleteFavouriteItem: '/deleteFavouriteItem',
  changePassword: '/changePassword',
  getCountries: '/getCountries',
  getBillingAccountDetails: '/getBillingAccountDetails',
  saveNewAddress: '/saveNewAddress',
  addAmendAccount: '/addAmendAccount',
  updateEmail: '/updateEmail',
  getDeliveryOptions: '/getDeliveryOptions',
  getOrderHistory : '/getOrderHistory',
  quickCartSearch : '/quickCartSearch',
  getProductPriceBreakdown : '/getProductPriceBreakdown',
  getNewProducts : '/getNewProducts',
  getUserAccountBalance : '/getUserAccountBalance',
  getOrderItems : '/getOrderItems',
  addToAccount : '/addToAccount',
  SaveOutstandingInvoices : '/SaveOutstandingInvoices',
  worldpayCallback : '/payment/callback',
  fetchOrderData : '/thankyou',
}
export default pathObj
