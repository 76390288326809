import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CommonService } from '../../../src/services/common';
import pathObj from '../../../src/services/Config';

const MegaMenu = () => {
  const [megaMenu, setMegaMenu] = useState([]);
  const hasFetched = useRef(false);
  const [activeSubCategory, setActiveSubCategory] = useState('');
  const [dropDownId, setDropDownId] = useState('');
  const params = useLocation();
  const currentCat = params?.pathname?.split('/').pop();

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const [megaMenuData] = await Promise.all([
          CommonService.getListing(`${pathObj.baseURL}${pathObj.getMegaMenu}`)
        ]);
        
        setMegaMenu(megaMenuData?.data);        
        megaMenuData?.data.flat().forEach(category => {
          // Check category level
          if (category?.slug === currentCat) {
            localStorage.setItem('productFilterString', category?.product_filter_string);
          }
        
          // Check SubCategories level
          category?.SubCategories?.forEach(subCategory => {
            if (subCategory?.slug === currentCat) {
              localStorage.setItem('productFilterString', subCategory?.product_filter_string);
            }
        
            // Check SubCategoriesChild level
            subCategory?.SubCategoriesChilds?.forEach(subCategoryChild => {
              if (subCategoryChild?.slug === currentCat) {
                localStorage.setItem('productFilterString', subCategoryChild?.product_filter_string);
              }
            });
          });
        });
        
        

        // Set the first category as active by default
        // if (megaMenuData?.data?.length > 0) {
        //   setActiveSubCategory(megaMenuData.data[0].Name);
        // }
      } catch (error) {
        console.error("Error fetching header info:", error);
      }
    };

    if (!hasFetched.current) {
      fetchMenu();
      hasFetched.current = true;
    }
  }, []);

  const handleSubCategoryChildClick = (category, subCategory, child, productFilterString) => {
    localStorage.setItem('productFilterString', productFilterString)
    // navigate(`/products/${category}/${subCategory}/${child}`);
    window.location.href = `/products/${category}/${subCategory}/${child}`;
  };

  const handleCategoryClicks = (category, subCategory, productFilterString) => {
    localStorage.setItem('productFilterString', productFilterString)
    // Find the selected subcategory
    const selectedSubCategory = megaMenu.find(cat => cat.slug === category)?.SubCategories.find(subCat => subCat.slug === subCategory);

    // Check if the SubCategoriesChilds array is empty
    if (selectedSubCategory?.SubCategoriesChilds?.length === 0) {
      // Redirect to the desired URL
      window.location.href = `/products/${category}/${subCategory}`;
      // navigate(`/products/${category}/${subCategory}`);
    } else {
      // Navigate normally if SubCategoriesChilds are present
      window.location.href = `/categories/${category}/${subCategory}`;
      // navigate(`/categories/${category}/${subCategory}`);
    }
  };

  const handleCategoryClick = (category, productFilterString) => {
    localStorage.setItem('productFilterString', productFilterString)
    // navigate(`/categories/${category}`);
    const targetUrl = category === 'New%20Products' ? `/new-products` : `/categories/${category}`;
    window.location.href = targetUrl; // Use the targetUrl variable
  };

  const handleDropDownMenuClick = (ID, category) => {
    localStorage.setItem('productFilterString', category?.product_filter_string)
    let targetUrl = '';
    if (category?.slug === 'New%20Products') {
      targetUrl = `/new-products`;
    } else if (category?.slug === 'New%20Top%20Categories') {
      targetUrl = `/new-top-categories`;
    } else if (category?.slug === 'Offers') {
      targetUrl = `/categories/Offers`;
    }

    if (targetUrl) {
      // window.location.href = targetUrl;
    }

    setDropDownId(prevId => (prevId === ID ? '' : ID)); // Toggle dropdown based on ID
    setActiveSubCategory(category.Name);
  };

  return (
    <div className="menu_wrap">
      <div className="container">
        <nav className="navbar navbar-expand-xl">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              {megaMenu?.map((category, index) => {
                return (
                  <li key={category?.id} className={`nav-item ${activeSubCategory === category?.Name ? 'active' : ''}`}>
                    <Link className="nav-link"
                      // to={category?.slug === 'new-products' ? `/new-product` : `/categories/${category?.slug}`}
                      to="#"
                      aria-expanded="false"
                      onClick={() => handleDropDownMenuClick(category?.id, category)}
                    >
                      {category?.Name}
                    </Link>
                    {dropDownId === category?.id && category?.SubCategories?.length > 0 && (
                      <div className="dropdown-menu">
                        <div className="mega_menu_content">
                          <ul className="level1">
                            <li
                              key={category?.id}
                              className={activeSubCategory === category?.Name ? 'active' : ''}
                              onMouseEnter={() => setActiveSubCategory(category?.Name)}
                            >
                              <Link className="has_child" aria-expanded="false"
                                // to={`/categories/${category?.slug}`}
                                to="#"
                                onClick={() => handleCategoryClick(category?.slug, category?.product_filter_string)}
                              >
                                {category?.Name}
                                <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.799998 0.673455C0.586665 0.673455 0.386666 0.754434 0.234666 0.903332C-0.0773329 1.20896 -0.0773329 1.70529 0.234666 2.01093L6.06932 7.72652C6.27465 7.92766 6.39199 8.21239 6.39199 8.51019C6.39199 8.80799 6.27465 9.09272 6.06932 9.29386L0.234666 15.0095C0.0800001 15.161 0 15.3621 0 15.5633C0 15.7644 0.0773335 15.9655 0.234666 16.117C0.391999 16.2686 0.586665 16.3469 0.799998 16.3469C1.01333 16.3469 1.21333 16.2659 1.36533 16.117L7.19998 10.4015C7.71465 9.89729 7.99998 9.22333 7.99998 8.51019C7.99998 7.79705 7.71465 7.12309 7.19998 6.61892L1.36533 0.903332C1.21333 0.754434 1.01333 0.673455 0.799998 0.673455Z" fill="currentcolor" />
                                </svg>
                              </Link>
                              {category?.SubCategories?.length > 0 && (
                                <div className="mega_menu">
                                  <ul className="level2">
                                    {category?.SubCategories.map(child => {
                                      return (
                                        <li key={child.id}>
                                          <Link
                                            // to={`/categories/${category?.slug}/${child?.slug}`}
                                            to="#"
                                            onClick={() => handleCategoryClicks(category?.slug, child?.slug, child?.product_filter_string)}>
                                            <img src={child?.image || `${pathObj?.s3ImagePath}/no-image.jpg`} alt={child?.Name} />
                                            <span className="menu_category_name">{child?.Name}</span>
                                          </Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              )}
                            </li>
                            {category?.SubCategories.map(subCategory => (
                              <li key={subCategory?.id} className={activeSubCategory === subCategory?.Name ? 'active' : ''}>
                                <Link className={`has_child`}
                                  // to={`/categories/${category?.slug}/${subCategory?.slug}`}
                                  to="#"
                                  data-bs-toggle={subCategory?.SubCategoriesChilds?.length > 0 ? '' : ''} aria-expanded="false"
                                  onMouseEnter={() => setActiveSubCategory(subCategory?.Name)}
                                  onClick={() => handleCategoryClicks(category?.slug, subCategory?.slug, subCategory?.product_filter_string)}
                                >
                                  {subCategory?.Name}
                                  {subCategory?.SubCategoriesChilds?.length > 0 && ( // Show SVG only if SubCategoriesChilds exist
                                    <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0.799998 0.673455C0.586665 0.673455 0.386666 0.754434 0.234666 0.903332C-0.0773329 1.20896 -0.0773329 1.70529 0.234666 2.01093L6.06932 7.72652C6.27465 7.92766 6.39199 8.21239 6.39199 8.51019C6.39199 8.80799 6.27465 9.09272 6.06932 9.29386L0.234666 15.0095C0.0800001 15.161 0 15.3621 0 15.5633C0 15.7644 0.0773335 15.9655 0.234666 16.117C0.391999 16.2686 0.586665 16.3469 0.799998 16.3469C1.01333 16.3469 1.21333 16.2659 1.36533 16.117L7.19998 10.4015C7.71465 9.89729 7.99998 9.22333 7.99998 8.51019C7.99998 7.79705 7.71465 7.12309 7.19998 6.61892L1.36533 0.903332C1.21333 0.754434 1.01333 0.673455 0.799998 0.673455Z" fill="currentcolor" />
                                    </svg>
                                  )}
                                </Link>
                                {subCategory?.SubCategoriesChilds?.length > 0 ? (
                                  <div className="mega_menu">
                                    <ul className="level2">
                                      {subCategory?.SubCategoriesChilds.map(child => (
                                        <li key={child?.id}>
                                          <Link
                                            // to={`/products/${category?.slug}/${subCategory?.slug}/${child?.slug}`}
                                            to="#"
                                            onClick={() => handleSubCategoryChildClick(category?.slug, subCategory?.slug, child?.slug, child?.product_filter_string)}>
                                            <img src={child?.image || `${pathObj?.s3ImagePath}/no-image.jpg`} alt={child?.Name} />
                                            <span className="menu_category_name">{child?.Name}</span>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="mega_menu">
                                    <h4 className="not_found_category" style={{ textAlign: 'center', paddingTop: '10rem' }}>You can see the products by clicking on required category.</h4>
                                  </div>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MegaMenu;
